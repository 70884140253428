import { ComponentType, lazy, LazyExoticComponent } from 'react';

const HomePage = lazy(() => import('./start-page'));
const ClientsPage = lazy(() => import('./clients'));
const ClientDetailsPage = lazy(() => import('./client-details'));
const UsersPage = lazy(() => import('./users'));
const PagesPage = lazy(() => import('./pages'));
const WidgetsPage = lazy(() => import('./widgets'));
const CustomerRolesPage = lazy(() => import('./customer-roles'));
const FileFormatsPage = lazy(() => import('./file-formats'));
const FileFormatDefinitionPage = lazy(
	() => import('./file-format-definitions'),
);
const FileMonitoringPage = lazy(() => import('./file-monitoring'));
const LoggingPage = lazy(() => import('./logging'));

interface PageRoute {
	path: string;
	name: string;
	exact: boolean;
	component: LazyExoticComponent<ComponentType<any>>;
	icon?: string;
	hidden?: boolean;
}

const routes: PageRoute[] = [
	{
		path: '/',
		name: 'Start page',
		exact: true,
		icon: 'home',
		component: HomePage,
	},
	{
		path: '/clients',
		name: 'Clients',
		exact: true,
		icon: 'business',
		component: ClientsPage,
	},
	{
		path: '/clients/:id',
		name: 'Client details',
		exact: true,
		component: ClientDetailsPage,
		hidden: true,
	},
	{
		path: '/users',
		name: 'Users',
		exact: true,
		icon: 'people',
		component: UsersPage,
	},
	{
		path: '/pages',
		name: 'Pages',
		exact: true,
		component: PagesPage,
		icon: 'pages',
	},
	{
		path: '/widgets',
		name: 'Widgets',
		exact: true,
		component: WidgetsPage,
		icon: 'widgets',
	},
	{
		path: '/customer-roles',
		name: 'Customer Roles',
		exact: true,
		component: CustomerRolesPage,
		icon: 'account_tree',
	},
	{
		path: '/file-formats',
		name: 'File Formats',
		exact: true,
		component: FileFormatsPage,
		icon: 'description',
	},
	{
		path: '/file-formats/:id',
		name: 'File Format Details',
		exact: true,
		component: FileFormatDefinitionPage,
		hidden: true,
	},
	{
		path: '/file-monitoring',
		name: 'File Monitoring',
		icon: 'monitor',
		exact: true,
		component: FileMonitoringPage,
	},
	{
		path: '/logging',
		name: 'Logging',
		icon: 'assignment',
		exact: true,
		component: LoggingPage,
	},
];

export default routes;
